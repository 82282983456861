<template>
  <div class="activity">
    <div class="inner">
      <img src="../../assets/images/activity/header.png" alt="" srcset="">
      <img src="../../assets/images/activity/bg1.png" alt="" srcset="">
      <img src="../../assets/images/activity/bg2.png" alt="" srcset="">
      <img src="../../assets/images/activity/bg3.png" alt="" srcset="" @click="jump('/cs01')">
      <img src="../../assets/images/activity/bg4.png" alt="" srcset="" >
      <img src="../../assets/images/activity/bg5.png" alt="" srcset="" @click="jump('/csx2')">
      <img src="../../assets/images/activity/bg6.png" alt="" srcset="">
      <img src="../../assets/images/activity/bg7.png" alt="" srcset="" @click="jump('/csx7')">
      <img src="../../assets/images/activity/bg8.png" alt="" srcset="">
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    jump(path){
      this.$router.push(path)
    }
  }
}
</script>

<style lang="scss" scoped>

.activity {
  display: flex;
  justify-content: center;
  background-image: url("../../assets/images/activity/bg9.png");
  background-size: 100% 100%;
  .inner {
    margin:0 auto;
    max-width: 1440px;

    img {
      display: block;
      width: 100%;
    } 
  }
}

</style>